import React from "react"

interface FrequentlyAskedQuestion {
   question: string,
   answer: JSX.Element[]
}

const questions_list: FrequentlyAskedQuestion[] = [
   {
      question: "When will I receive my tickets",
      answer: [
         <React.Fragment>
            <p>Tickets will become available when the event organiser releases them for distribution. This can vary by event. However, we usually send physical or electronic tickets between 3-7 days before the event date.</p>
         </React.Fragment>
      ]
   },
   {
      question: "What is the dress code?",
      answer: [
         <React.Fragment>
            <p>Not every event that we sell tickets for will require a dress code, but many do. Each event varies, please check your booking information for further details.</p>
         </React.Fragment>
      ]
   },
   {
      question: "Is accomodation and/or travel included in my package?",
      answer: [
         <React.Fragment>
            <p>Travel and accomodation may be included in your package, depending on the level of of package that you have purchased. If it does not, we can arrange this for you for an additional charge. Please consult your account manager for further details.</p> 
         </React.Fragment>
      ]
   },
   {
      question: "Is there a minimum/maximum number that can attend an event?",
      answer: [
         <React.Fragment>
            <p>If availability is limited for a certain event, we may not be able to accomodate large numbers of attendees, however, generally speaking there is no minimum or maximum number of guests that can attend our events.</p>
         </React.Fragment>
      ]
   },
   {
      question: "How do I share dietary requirements with you?",
      answer: [
         <React.Fragment>
            <p>We will send a dietary form shortly after booking that will allow you to share any requirements for you and your guests. This will then be shared with the venue.</p>
         </React.Fragment>
      ]
   },
   {
      question: "When and how do I pay?",
      answer: [
         <React.Fragment>
            <p>You will pay a deposit upon completion of your booking, with the remaining balance to be paid at a later date, usually 28 working days after booking. This can vary so please consult your account manager for further details.</p>
         </React.Fragment>
      ]
   },
   {
      question: "What payment methods do you accept?",
      answer: [
         <React.Fragment>
            <p>We accept credit/debit cards from all major providers, including American Express. We also accept BACS payments.</p>
         </React.Fragment>
      ]
   },
   {
      question: "Do your prices include VAT?",
      answer: [
         <React.Fragment>
            <p>We are VAT registered and all bookings are subject to VAT.</p>
         </React.Fragment>
      ]
   }
]

export default questions_list