import React from "react";

const CallUsSidebarCTA: React.FC = (): JSX.Element => {
   return (
      <div className="standard-sidebar-cta">
         <h2>Give us a quick call</h2>

         <p>Let us walk you through your query</p>

         <a className="standard-button orange" href="tel:+443333055377">0333&nbsp;305&nbsp;5377</a>
      </div>
   )
}

export default CallUsSidebarCTA