import React, { useState } from "react";
import questions_list from "./resources/questions-list";

interface FAQTypes {
   shortened?: boolean
}

const FAQsWidget: React.FC<FAQTypes> = ({ shortened }) => {
   const [selectedAccordion, setSelectedAccordion] = useState<string>('')

   const handleChangeAccordion = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = e.target;

      if(checked === false) {
         setSelectedAccordion('')
      } else {
         setSelectedAccordion(name)
      }
   }

   let final_list = questions_list;
   if(shortened === true) final_list = questions_list.slice(0, 5)

   return (
      <React.Fragment>
         {final_list.map((question, index) => (
            <React.Fragment>
               <input
                  className="standard-accordion-trigger invisible"
                  id={`q${index}`}
                  name={`q${index}`}
                  type="checkbox"
                  checked={selectedAccordion === `q${index}`}
                  onChange={(e) => handleChangeAccordion(e)}
               />

               <label htmlFor={`q${index}`} className={`standard-accordion-title compact ${shortened ? 'green' : ''}`}>
                  <h3>{question.question}</h3>
               </label>

               <div className="standard-accordion-body">
                  {question.answer}
               </div>
            </React.Fragment>
         ))}

         {shortened ? (
            <center>
               <br/>
               <br/>
               <a href="/faq" className="standard-button orange">See all FAQs</a>
            </center>
         ) : null}
      </React.Fragment>
   )
}

export default FAQsWidget