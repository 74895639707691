import { HeadFC } from "gatsby";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import CookiesConsentNotice from "../components/cookies-consent/cookies-consent";
import CallUsSidebarCTA from "../components/ctas/call-us-sidebar";
import FAQsWidget from "../components/faqs-widget/faqs-widget";
import Footer from "../components/footer/footer";
import Navigation from "../components/navigation/navigation";
import SEO from "../components/seo/seo";

// CSS
import StylesComponent from "../components/styles/styles-component";
import "../sass/page-specific/policy-styles.scss";

const Contact: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   const [selectedAccordion, setSelectedAccordion] = useState<string>('');

   const handleChangeAccordion = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = e.target;

      if(checked === false) {
         setSelectedAccordion('')
      } else {
         setSelectedAccordion(name)
      }
   }

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="policy-hero-outer-container">
            <div className="policy-hero-container-overlay">
               <div className="policy-hero-text-container container-width">
                  <h1>Contact us</h1>
                  <p className="secondary-text">Find all of our contact methods on this page</p>
                  
                  <br/>

                  <a href="/events#package-enquiry" className="standard-button orange">Make event enquiry</a>
               </div>
            </div>
         </div>

         <div className="policy-content-outer-container">
            <div className="policy-content-grid-container container-width">
               <div className="main-content-container">
                  <Breadcrumbs location="contact"/>

                  <p>Choose the method of contact that you would like to use and then follow the instructions to get in touch with us! We look forward to speaking with you.</p>

                  {/* Call us */}
                  <input 
                     className="standard-accordion-trigger invisible"
                     id="call"
                     name="call"
                     type="checkbox"
                     checked={selectedAccordion === 'call'}
                     onChange={(e) => handleChangeAccordion(e)}
                  />

                  <label htmlFor="call" className="standard-accordion-title">
                     <span>
                        <h3>Call us</h3>
                        <p className="subheading">Monday - Friday, 9am - 6pm</p>
                     </span>
                  </label>

                  <div className="standard-accordion-body">
                     <h4>Opening hours</h4>
                     <ul>
                        <li>Weekdays: 9am - 6pm</li>
                        <li>Weekends: Closed</li>
                     </ul>

                     <p>Speak to an advisor by calling</p>
                     <a className="phone-number" href="tel:+443333055377">0333&nbsp;305&nbsp;5377</a>
                  </div>

                  {/* Email us */}
                  <input 
                     className="standard-accordion-trigger invisible"
                     id="email"
                     name="email"
                     type="checkbox"
                     checked={selectedAccordion === 'email'}
                     onChange={(e) => handleChangeAccordion(e)}
                  />

                  <label htmlFor="email" className="standard-accordion-title">
                     <span>
                        <h3>Email us</h3>
                        <p className="subheading">Responses during opening hours</p>
                     </span>
                  </label>

                  <div className="standard-accordion-body">
                     <h4>Office hours</h4>
                     <p>You will receive a response during these hours</p>
                     <ul>
                        <li>Weekdays: 9am - 6pm</li>
                        <li>Weekends: Closed</li>
                     </ul>

                     <p>Email support at the following address</p>
                     <a className="phone-number" href="mailto:support@prestige-vip.com">support@prestige-vip.com</a>
                  </div>

                  {/* Social media */}
                  <input 
                     className="standard-accordion-trigger invisible"
                     id="social"
                     name="social"
                     type="checkbox"
                     checked={selectedAccordion === 'social'}
                     onChange={(e) => handleChangeAccordion(e)}
                  />

                  <label htmlFor="social" className="standard-accordion-title">
                     <span>
                        <h3>Social media</h3>
                        <p className="subheading">Available 24/7</p>
                     </span>
                  </label>

                  <div className="standard-accordion-body">
                     <p>Our social media team will respond 24/7 subject to demand</p>

                     <p>Find your favourite social media on the following link</p>
                     <a href="https://allmylinks.com/prestigevipuk" target="_blank" className="standard-button orange">See social links</a>
                  </div>

                  {/* WhatsApp */}
                  <input 
                     className="standard-accordion-trigger invisible"
                     id="whatsapp"
                     name="whatsapp"
                     type="checkbox"
                     checked={selectedAccordion === 'whatsapp'}
                     onChange={(e) => handleChangeAccordion(e)}
                  />

                  <label htmlFor="whatsapp" className="standard-accordion-title">
                     <span>
                        <h3>WhatsApp</h3>
                        <p className="subheading">Available 24/7</p>
                     </span>
                  </label>

                  <div className="standard-accordion-body">
                     <p>Our customer team will respond to you 24/7 via WhatsApp subject to demand</p>

                     <p>Chat with an advisor by messaging</p>
                     <a className="phone-number" href="tel:+443333055377">0333&nbsp;305&nbsp;5377</a>
                  </div>

                  {/* Write to us */}
                  <input 
                     className="standard-accordion-trigger invisible"
                     id="write"
                     name="write"
                     type="checkbox"
                     checked={selectedAccordion === 'write'}
                     onChange={(e) => handleChangeAccordion(e)}
                  />

                  <label htmlFor="write" className="standard-accordion-title">
                     <span>
                        <h3>Write to us</h3>
                        <p className="subheading">Response within 3 business days</p>
                     </span>
                  </label>

                  <div className="standard-accordion-body">
                     <p>Our customer team will respond to your letter within 3 business days. Response arrival subject to 2nd class mail delivery schedules.</p>

                     <p><u>Write to us at the following address</u></p>
                     
                     <p>Prestige Corporate Events<br/>
                     265 - 269 Wimbledon Park Road<br/>
                     Wimbledon<br/>
                     London<br/>
                     SW19 6NW</p>
                  </div>
               </div>

               <div className="content-sidebar-container">
                  <div className="sidebar-cta-wrapper">
                     <CallUsSidebarCTA/>
                  </div>
               </div>
            </div>
         </div>

         <div className="faq-outer-container">
            <div className="faq-inner-container container-width">
               <center>
                  <h2>Frequently Asked Questions (FAQs)</h2>
                  <p style={{maxWidth: 700}} className="secondary-text dark">Find answers to our most frequently asked questions (FAQs) before you get in touch in case there is a quick and easy answer. If you can't find the answer, or you'd prefer to just get straight in touch, please do!</p>
               </center>

               <FAQsWidget shortened={true}/>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default Contact

export const Head: HeadFC = () => (
   <SEO
      title="Contact us | Prestige VIP"
      metaDescription="Welcome to Prestige VIP, get in touch with us to talk through enquiries, support or anything else. Find all contact options on this page."
      slug="/contact"
   />
)
